export interface FontDescription {
  fontFamily: string;
  displayName: string;
  cdnName: string;
  fallbacks: string;
  genericFamily: string;
  provider: string;
  characterSets: string[];
  fontFaceDeclaration: string;
}

// Font faces source:
// https://github.com/wix-private/thunderbolt/blob/e183ceb154b169f110fda7c703641dbbcec6cd07/packages/thunderbolt-becky-root/src/functionLibraryExtensions/fonts/fonts.google.ts
export const fontsMetadata: Record<string, FontDescription> = {
  caudex: {
    displayName: 'Caudex',
    fontFamily: 'caudex',
    cdnName: 'Caudex',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 400;
      src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKPtbosEJimIMs.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 400;
      src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKAtbosEJimIMs.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 400;
      src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKNtbosEJimIMs.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 400;
      src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKDtbosEJim.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 700;
      src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8NMpWMGsLAjdA.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 700;
      src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8CMpWMGsLAjdA.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 700;
      src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8PMpWMGsLAjdA.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: italic;
      font-weight: 700;
      src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8BMpWMGsLA.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 400;
      src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4wfKBv70OEqin.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 400;
      src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4zvKBv70OEqin.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 400;
      src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4w_KBv70OEqin.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 400;
      src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4zfKBv70OEg.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 700;
      src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUnp8DOJKuGPLB.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 700;
      src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUkZ8DOJKuGPLB.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 700;
      src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUnJ8DOJKuGPLB.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Caudex';
      font-style: normal;
      font-weight: 700;
      src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUkp8DOJKuGA.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  corben: {
    displayName: 'Corben',
    fontFamily: 'corben',
    cdnName: 'Corben',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Corben';
      font-style: normal;
      font-weight: 400;
      src: local('Corben Regular'), local('Corben-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjDdGzzklQtCMpNqQNFh1gnVOfe.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Corben';
      font-style: normal;
      font-weight: 400;
      src: local('Corben Regular'), local('Corben-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjDdGzzklQtCMpNpwNFh1gnVA.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Corben';
      font-style: normal;
      font-weight: 700;
      src: local('Corben'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjAdGzzklQtCMpFHCZQpHoqft3XWKyz.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Corben';
      font-style: normal;
      font-weight: 700;
      src: local('Corben'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjAdGzzklQtCMpFHCZQqnoqft3XWA.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  forum: {
    displayName: 'Forum',
    fontFamily: 'forum',
    cdnName: 'Forum',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Forum';
      font-style: normal;
      font-weight: 400;
      src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IcOpI43XnSBTM.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Forum';
      font-style: normal;
      font-weight: 400;
      src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IVOpI43XnSBTM.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Forum';
      font-style: normal;
      font-weight: 400;
      src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IfOpI43XnSBTM.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Forum';
      font-style: normal;
      font-weight: 400;
      src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IROpI43XnS.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  'din-next-w01-light': {
    displayName: 'DIN Next Light',
    fontFamily: 'din-next-w01-light',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'din-next-w02-light,din-next-w10-light',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "DIN-Next-W01-Light";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
    }`,
  },
  'helvetica-w01-roman': {
    displayName: 'Helvetica',
    fontFamily: 'helvetica-w01-roman',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'helvetica-w02-roman,helvetica-lt-w10-roman',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica-W01-Roman";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4021a3b9-f782-438b-aeb4-c008109a8b64.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/669f79ed-002c-4ff6-965c-9da453968504.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d17bc040-9e8b-4397-8356-8153f4a64edf.svg#d17bc040-9e8b-4397-8356-8153f4a64edf") format("svg");
    }`,
  },
  'helvetica-w01-bold': {
    displayName: 'Helvetica Bold',
    fontFamily: 'helvetica-w01-bold',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'helvetica-w02-bold,helvetica-lt-w10-bold',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica-W01-Bold";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c5749443-93da-4592-b794-42f28d62ef72.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73805f15-38e4-4fb7-8a08-d56bf29b483b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/874bbc4a-0091-49f0-93ef-ea4e69c3cc7a.svg#874bbc4a-0091-49f0-93ef-ea4e69c3cc7a") format("svg");
    }`,
  },
  'helvetica neue': {
    displayName: 'Helvetica Neue',
    fontFamily: 'helvetica neue',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: '',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica Neue";
      src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("embedded-opentype"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
  }`,
  },
  'futura-lt-w01-book': {
    displayName: 'Futura',
    fontFamily: 'futura-lt-w01-book',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'futura-lt-w05-book',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Futura-LT-W01-Book";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
    }`,
  },
  'avenir-lt-w01_85-heavy1475544': {
    displayName: 'Avenir',
    fontFamily: 'avenir-lt-w01_85-heavy1475544',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'avenir-lt-w05_85-heavy',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Avenir-LT-W01_85-Heavy1475544";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
    }`,
  },
  'avenir-lt-w01_35-light1475496': {
    displayName: 'Avenir Light',
    fontFamily: 'avenir-lt-w01_35-light1475496',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'avenir-lt-w05_35-light',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Avenir-LT-W01_35-Light1475496";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c") format("svg");
    }`,
  },
  'roboto-bold': {
    displayName: 'Roboto Bold',
    fontFamily: 'roboto-bold',
    cdnName: 'Roboto',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'roboto',
    fontFaceDeclaration: `
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic3CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic-CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic2CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic5CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic1CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic0CsTYl4BOQ3o.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 700;
      src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* greek-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+1F00-1FFF;
      }
      /* greek */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+0370-03FF;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* Roboto Bold*/
      @font-face {
          font-display: block;
          font-family: "Roboto-Bold";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aW1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aWz8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aW6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-Svg.svg") format("svg");
      }
      /* Roboto Bold*/
      @font-face {
          font-display: block;
          font-family: "Roboto-Bold";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkltXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkj8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkqCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-700-Svg.svg") format("svg");
      }
      /* Roboto Bold*/
      @font-face {
          font-display: block;
          font-family: "Roboto-Bold";
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC6g5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC9Ih4imgI8P11RFo6YPCPC0.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC102b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-italic-Svg.svg") format("svg");
      }
      /* Roboto Bold*/
      @font-face {
          font-display: block;
          font-family: "Roboto-Bold";
          font-weight: 700;
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpag5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpdIh4imgI8P11RFo6YPCPC0.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpV02b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-700-italic-Svg.svg") format("svg");
      }
    `,
  },
  'poppins-extralight': {
    displayName: 'Poppins Extra Light',
    fontFamily: 'poppins-extralight',
    cdnName: 'Poppins',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'poppins',
    fontFaceDeclaration: `
      /* Poppins-ExtraLight */
      @font-face {
          font-display: block;
          font-family: "Poppins-ExtraLight";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGPk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGBsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGC3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-Svg.svg") format("svg");
      }
      /* Poppins-ExtraLight */
      @font-face {
          font-display: block;
          font-family: "Poppins-ExtraLight";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/rijG6I_IOXJjsH07UEo2mw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/p0A1C4_gK5NzKtuGSwNurQ.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/rATt6MpBkxjRr3sy5fMEDg.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-700-Svg.svg") format("svg");
      }
      /* Poppins-ExtraLight */
      @font-face {
          font-display: block;
          font-family: "Poppins-ExtraLight";
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKRUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKRa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKdqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-italic-Svg.svg") format("svg");
      }
      /* Poppins-ExtraLight */
      @font-face {
          font-display: block;
          font-family: "Poppins-ExtraLight";
          font-weight: 700;
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQVtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQT8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQaCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-700-italic-Svg.svg") format("svg");
      }
    `,
  },
  'oswald-medium': {
    displayName: 'Oswald Medium',
    fontFamily: 'oswald-medium',
    cdnName: 'Oswald',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'oswald',
    fontFaceDeclaration: `
      /* Oswald-Medium */
      @font-face {
          font-display: block;
          font-family: "Oswald-Medium";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajD8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-Svg.svg") format("svg");
      }
      /* Oswald-Medium */
      @font-face {
          font-display: block;
          font-family: "Oswald-Medium";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb31tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb3z8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb36CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-700-Svg.svg") format("svg");
      }
    `,
  },
  'cormorantgaramond-light': {
    displayName: 'Cormorant Garamond Light',
    fontFamily: 'cormorantgaramond-light',
    cdnName: 'Cormorant+Garamond',
    genericFamily: 'serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'cormorantgaramond,cormorant garamond',
    fontFaceDeclaration: `
      /* CormorantGaramond-Light */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-Light";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVSNMxVe3WGf96EDbCaLCBKE.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVergGQquJ_f3dxTxEJk8ZKM.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXYC8UqYVZ_Us7w6eA7MdZE.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-Svg.svg") format("svg");
      }
      /* CormorantGaramond-Light */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-Light";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXP87xhFzkXvitf5EbJwljk.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVebEnH4R5m1MLXJyCi0BC78.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXXouEby_DX2rsmMI51GE6g.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-700-Svg.svg") format("svg");
      }
      /* CormorantGaramond-Light */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-Light";
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhjqQayVfgmnRFwqYqN-Dis.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhNmD6bOwmI0fiYv_Ehe03s.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAoUBHI3ylZGW9V5Fst4kWps.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-italic-Svg.svg") format("svg");
      }
      /* CormorantGaramond-Light */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-Light";
          font-weight: 700;
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAjp2K1CgsixPpkXulytJk5A.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhX141arjC0UgpdoDjjeeVk.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAs_ggsKlhsDnft5n268BUmY.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-700-italic-Svg.svg") format("svg");
      }
    `,
  },
  'cormorantgaramond-semibold': {
    displayName: 'Cormorant Garamond Semi Bold',
    fontFamily: 'cormorantgaramond-semibold',
    cdnName: 'Cormorant+Garamond',
    genericFamily: 'serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'cormorantgaramond,cormorant garamond',
    fontFaceDeclaration: `
      /* CormorantGaramond-SemiBold */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-SemiBold";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVVBiiiFZ1SMKhjDurTuPCI4.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVbaDr2DD9WOmTsY4M3S93hU.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVeXPWxx9SjLSy6MMhsXoUuc.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-Svg.svg") format("svg");
      }
      /* CormorantGaramond-SemiBold */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-SemiBold";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVVYUpUlN7yzNHgIMH66hSOI.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVQIBIRsdTZvmhTwexVJEOCE.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVaDtWBNc4GTxi9CQqfNpXFo.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-700-Svg.svg") format("svg");
      }
      /* CormorantGaramond-SemiBold */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-SemiBold";
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAoNfVaeyxI1fRb3LCiKLt24.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAggc7XrJygm306pFqSI3kLU.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAtfqxAW0UHKApQgkrKaDcls.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-italic-Svg.svg") format("svg");
      }
      /* CormorantGaramond-SemiBold */
      @font-face {
          font-display: block;
          font-family: "CormorantGaramond-SemiBold";
          font-weight: 700;
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAoWXH9gdibkBmfnjU2pcZcs.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAsgX3acpz6D8mJOI1MynZxQ.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAr5MZRoaqvZ37XiNNIvX6C0.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-700-italic-Svg.svg") format("svg");
      }
    `,
  },
  'lato-light': {
    displayName: 'Lato Light',
    fontFamily: 'lato-light',
    cdnName: 'Lato',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'lato',
    fontFaceDeclaration: `
      /* Lato-Light */
      @font-face {
          font-display: block;
          font-family: "Lato-Light";
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/2hXzmNaFRuKTSBR9nRGO-A.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/kU6VHbqMAZhaN_nXCmLQsQ.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/BTu4SsVveqk58cdYjlaM9g.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-Svg.svg") format("svg");
      }
      /* Lato-Light */
      @font-face {
          font-display: block;
          font-family: "Lato-Light";
          font-weight: 700;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/7nLfsQCzhQW_PwpkrwroYw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/I1Pn3gihk5vyP0Yw5GqKsQ.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/zpv3sOKAbMf4wff105oLjw.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-700-Svg.svg") format("svg");
      }
      /* Lato-Light */
      @font-face {
          font-display: block;
          font-family: "Lato-Light";
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNEFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNED8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNEKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-italic-Svg.svg") format("svg");
      }
      /* Lato-Light */
      @font-face {
          font-display: block;
          font-family: "Lato-Light";
          font-weight: 700;
          font-style: italic;
          src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rn1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rnz8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rn6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
          url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-700-italic-Svg.svg") format("svg");
      }
    `,
  },
  questrial: {
    displayName: 'Questrial',
    fontFamily: 'questrial',
    cdnName: 'Questrial',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Questrial';
      font-style: normal;
      font-weight: 400;
      src: local('Questrial'), local('Questrial-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Questrial/v9/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  montserrat: {
    displayName: 'Montserrat',
    fontFamily: 'montserrat',
    cdnName: 'Montserrat',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8fZwjimrq1Q_.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz-PZwjimrq1Q_.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8_Zwjimrq1Q_.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8vZwjimrq1Q_.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz_PZwjimrqw.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2") format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2") format('woff2');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2") format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2") format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
      font-display: block;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2") format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  fahkwang: {
    displayName: 'Fahkwang',
    fontFamily: 'fahkwang',
    cdnName: 'Fahkwang',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiKpxOQR7mhDIbg.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLFxOQR7mhDIbg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLBxOQR7mhDIbg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiL5xOQR7mhA.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULD1ZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCZZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCdZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULClZlzryZw8.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqcuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqHuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqGuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqIuLx7PiZ5.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalCHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalZHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalYHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalWHCtTkBjw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLFxOQR7mhDIbg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLBxOQR7mhDIbg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiL5xOQR7mhA.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULD1ZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCZZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCdZlzryZw-MEA.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULClZlzryZw8.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqcuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqHuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqGuLx7PiZ5qhE.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqIuLx7PiZ5.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* thai */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalCHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalZHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalYHCtTkBjwVw4.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fahkwang';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalWHCtTkBjw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  fraunces: {
    displayName: 'Fraunces',
    fontFamily: 'fraunces',
    cdnName: 'Fraunces',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  syne: {
    displayName: 'Syne',
    fontFamily: 'syne',
    cdnName: 'Syne',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* latin-ext */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  'space grotesk': {
    displayName: 'Space Grotesk',
    fontFamily: 'space grotesk',
    cdnName: 'Space+Grotesk',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* vietnamese */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
  quicksand: {
    displayName: 'Quicksand',
    fontFamily: 'quicksand',
    cdnName: 'Quicksand',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
    fontFaceDeclaration: `
      /* vietnamese */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `,
  },
};
