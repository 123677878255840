import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { i18n } from './i18n/client-i18n';
import { App } from './App/App';
import * as BIManager from './utils/bi';
import { DEVICE } from './utils/helpers';
import { initWixRecorder } from './utils/initWixRecorder';
import supportChatWidget from '@wix/support-chat-widget-wrapper';
import { initCookieAndTagManagers } from './utils/initCookieAndTagManagers';
import { ContextProvider } from './context/ContextProvider';
import { ThemeContextProvider } from './context/ThemeContext/ThemeContextProvider';

const initialI18n = window.__INITIAL_I18N__;
const baseURL = window.__BASEURL__;
const initialState = window.__INITIAL_STATE__;
const consentPolicy = window.__CONSENT_POLICY__;
const device = window.__DEVICE__;
const biOptions = window.__BI__;

(() => {
  initCookieAndTagManagers({
    baseUrl: baseURL,
    consentPolicy,
    language: initialI18n.locale,
  });

  initWixRecorder(initialState.activeExperiments);
  window.addEventListener('load', () => {
    void supportChatWidget.init({ origin: 'TemplatesViewer' });
  });

  BIManager.setBIOptions(biOptions);

  const app = (
    <ContextProvider initialState={initialState}>
      <I18nextProvider i18n={i18n(initialI18n)}>
        <ThemeContextProvider>
          <App mobile={device !== DEVICE.DESKTOP} />
        </ThemeContextProvider>
      </I18nextProvider>
    </ContextProvider>
  );

  const container = document.getElementById('root');

  if (initialState.activeExperiments?.includes('TemplateViewerHydrationRender')) {
    hydrateRoot(container!, app, {
      onRecoverableError: (error) => {
        console.error(error);
        // @ts-expect-error
        window?.Sentry?.captureException(e);
      },
    });
  } else {
    const root = createRoot(container!);
    root.render(app);
  }
})();
