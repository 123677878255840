import {
  getFontCSSFromFontString,
  getThemeFontFamilies,
  getCustomFontDeclarations,
  getFontFamily,
  replaceFontFamilyAndColor,
} from './fonts/fontUtils';
import { DefaultTheme, Theme } from './ThemeContext';

const CUSTOM_FONTS_ID = 'CUSTOM_FONTS';

export const loadThemeFonts = (
  theme: Theme | DefaultTheme,
  document: Document,
  cssStyleProperties: CSSStyleDeclaration,
) => {
  const fontFamilies = getThemeFontFamilies(theme);
  const additionalFonts = getCustomFontDeclarations(fontFamilies);

  attachAdditionalFonts(additionalFonts, document);

  Object.entries(theme.fonts).forEach(([fontName, fontValue]) => {
    try {
      const themeFontString = getFontCSSFromFontString(fontValue);
      const currentFontString = cssStyleProperties?.getPropertyValue(`--${fontName}`);
      const fontString = replaceFontFamilyAndColor(currentFontString, themeFontString);
      cssStyleProperties?.setProperty(`--${fontName}`, fontString);
      if (!document?.fonts.check(fontString)) {
        document?.fonts.load(fontString);
      }
    } catch (e) {
      console.error(e);
      // @ts-expect-error
      window?.Sentry?.captureException(e);
    }
  });
};

export const loadMainFontFromInitialTheme = (theme: Pick<Theme, 'colors' | 'fonts'>, iframeDocument: Document) => {
  // @ts-expect-error
  const fontFaceRules: CSSFontFaceRule[] = Array.from(iframeDocument.styleSheets)
    .flatMap((sheet) => {
      let rules;
      try {
        rules = sheet.cssRules;
      } catch (e) {}
      return Array.from(rules ?? []);
    })
    .filter((rule) => rule.constructor.name === 'CSSFontFaceRule');
  const firstFontFamily = getFontFamily(theme.fonts.font_0).split(',')[0];
  const fontFaceRule = fontFaceRules.find(
    (rule) => rule.style.getPropertyValue('font-family').toLowerCase() === firstFontFamily.toLowerCase(),
  );

  try {
    if (fontFaceRule) {
      attachAdditionalFonts(fontFaceRule.cssText, document);
      const fontString = getFontCSSFromFontString(theme.fonts.font_0);
      document?.fonts.load(fontString);
    }
  } catch (e) {
    console.error(e);
    // @ts-expect-error
    window?.Sentry?.captureException(e);
  }
};

export function attachAdditionalFonts(additionalFonts: string, document: Document) {
  if (!document) {
    return;
  }
  document.getElementById(CUSTOM_FONTS_ID)?.remove();
  const style = document.createElement('style');
  style.id = CUSTOM_FONTS_ID;
  style.innerHTML = additionalFonts;
  document.head.appendChild(style);
}
