import React, { FC, useEffect } from 'react';
import { Desktop } from '@wix/wix-ui-icons-common/classic-editor';
import { ArrowLeftSmall, Mobile } from '@wix/wix-ui-icons-common';
import cs from 'classnames';
import { VIEW_MODE } from '../../utils/helpers';
import s from './ToolBar.scss';
import { WixLogo } from '../partials/WixLogo/WixLogo';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { InfoButton } from '../InfoButton/InfoButton';
import { useUIContext } from '../../context/UIContext/UIContext';
import { reportEditorClick } from '../../utils/bi';
import { toolBarDataHooks } from './ToolBar.dataHooks';
import { useExperimentContext } from '../../context/ExperimentContext/ExperimentContext';
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';
import { ThemeId } from '../../context/ThemeContext/constants/themeId';

function getEditorUrl(editorUrl: string, currentThemeId: ThemeId | null) {
  if (currentThemeId) {
    return `${editorUrl}&themeId=${currentThemeId}`;
  }

  return editorUrl;
}

const ToolBarCmp: FC<{ dataHook?: string } & WithTranslation> = ({ t, dataHook }) => {
  const { viewMode, toggleViewMode } = useUIContext();
  const { template } = useTemplateContext();
  const { activeExperiments } = useExperimentContext();
  const { currentThemeId } = useThemeContext();

  const isNewViewerPage = activeExperiments.includes('NewViewerPageCapabilities');
  const isShowBackButton =
    (template.backToTemplatesUrl || template.origin !== undefined) &&
    activeExperiments.includes('OpenTemplateInSameTabForDashboardFirstUsers');

  const editorUrl = getEditorUrl(template.editorUrl, currentThemeId);

  useEffect(() => {
    window.templateEditUrl = editorUrl; // Adoric need to have this link in the window
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorClick = () => {
    reportEditorClick({ origin: 'viewer_top' });
  };

  const onBackToTemplatesClick = () => {
    if (template.origin === 'intro') {
      window.history.back();
    } else if (template.backToTemplatesUrl !== undefined) {
      window.location.assign(template.backToTemplatesUrl);
    }
  };

  return (
    <div data-hook={dataHook} className={cs(s.toolBar, { [s.newView]: isNewViewerPage })}>
      <div className={s.row}>
        <div className={s.logo}>
          <WixLogo dataHook={toolBarDataHooks.logo} />
        </div>
        {isShowBackButton && (
          <>
            <hr className={s.backButtonDivider} />
            <div
              onClick={onBackToTemplatesClick}
              className={s.backToTemplatesButton}
              data-hook={toolBarDataHooks.backToTemplatesButton}
            >
              <ArrowLeftSmall className={s.backButtonIcon} />
              <div className={s.label}>{t('template.viewer.header.backToTemplates')}</div>
            </div>
          </>
        )}
        {!isNewViewerPage && (
          <div className={s.deviceToggle}>
            <button
              data-hook={toolBarDataHooks.desktopView}
              className={cs(s.desktopIcon, { [s.selected]: viewMode === VIEW_MODE.DESKTOP })}
              onClick={() => toggleViewMode(VIEW_MODE.DESKTOP)}
            >
              <span className={s.hide}>{t('a11y.desktop.button')}</span>
            </button>
            <hr className={s.hr} />
            <button
              data-hook={toolBarDataHooks.mobileView}
              className={cs(s.mobileIcon, { [s.selected]: viewMode === VIEW_MODE.MOBILE })}
              onClick={() => toggleViewMode(VIEW_MODE.MOBILE)}
            >
              <span className={s.hide}>{t('a11y.mobile.button')}</span>
            </button>
          </div>
        )}
      </div>
      <div className={cs({ [s.row]: isNewViewerPage }, s.actionMenu)}>
        {isNewViewerPage && (
          <div className={s.viewMode}>
            <button
              data-hook={toolBarDataHooks.desktopView}
              className={cs(s.deviceButton, { [s.selected]: viewMode === VIEW_MODE.DESKTOP })}
              onClick={() => (viewMode === VIEW_MODE.DESKTOP ? '' : toggleViewMode(VIEW_MODE.DESKTOP))}
            >
              <Desktop size={32} />
              <span className={s.hide}>{t('a11y.desktop.button')}</span>
            </button>
            <button
              data-hook={toolBarDataHooks.mobileView}
              className={cs(s.deviceButton, { [s.selected]: viewMode === VIEW_MODE.MOBILE })}
              onClick={() => (viewMode === VIEW_MODE.MOBILE ? '' : toggleViewMode(VIEW_MODE.MOBILE))}
            >
              <Mobile size={32} />
              <span className={s.hide}>{t('a11y.mobile.button')}</span>
            </button>
          </div>
        )}
        <div className={s.elementsGroup}>
          {!isNewViewerPage && (
            <p data-hook={toolBarDataHooks.toolBarTitle} className={isShowBackButton ? s.responsiveTitle : s.title}>
              {t('template.viewer.title')}
            </p>
          )}
          <InfoButton dataHook={toolBarDataHooks.infoView} />
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className={s.button}
            data-hook={toolBarDataHooks.editorLink}
            href={editorUrl}
            target="_blank"
            tabIndex={0}
            onClick={() => onEditorClick()}
          >
            {isNewViewerPage ? t('template.viewer.use.button') : t('template.viewer.edit.button')}
          </a>
        </div>
      </div>
    </div>
  );
};

export const ToolBar = withTranslation()(ToolBarCmp);
